@import "../config"
@import "/styles/config"

.developmentCategories
    display: flex
    flex-wrap: wrap
    gap: 2px
    margin-bottom: 24px
    +max-width($mobail)
        margin-bottom: 20px

    &__item
          background: #F6F8F9
          padding: 2px 8px
          border-radius: 4px
          font: 400 16px/24px $font
          color: #566369
          +max-width($mobail)
              font: 400 14px/21px $font