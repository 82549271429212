@import "../config"
@import "/styles/config"


.site-cards
	position: relative
	display: block
	padding: 0
	margin: 100px 0
	+max-width($mobail)
		margin: 40px 0

	&__wrapper
		position: relative

	&__title
		margin-bottom: 40px
		+max-width($mobail)
			margin-bottom: 12px

	&__grid
		position: relative
		display: flex
		flex-wrap: wrap
		+max-width($mobail)
			gap: 8px