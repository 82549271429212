@import "../config"
@import '/styles/config'

.runline_item
  height: 100%
  width: 19rem
  display: flex

  +max-width($tablet)
    width: 7.875rem


  .runline_logo
    height: 100%
    width: 100%
    max-height: 7rem
    margin: auto
    filter: grayscale(1)
    transition: filter 0.3s ease


    &:hover
      filter: grayscale(0)
