@import "../config"
@import "/styles/config"

.yandexPartner
    margin: 0
    padding-bottom: 60px
    +max-width($tablet)
        padding-bottom: 0


    &__wrapper
        display: flex
        gap: 40px
        +max-width($mobail)
            flex-direction: column
            gap: 20px

    &__img-wrapper
        display: flex
        align-items: center
        justify-content: center
        width: 48%
        +max-width($tablet)
            width: auto


    &__image
        +max-width($tablet)
            max-width: 300px
            max-height: 216px
        +max-width($mobail)
            max-width: 150px
            max-height: 108px

    &__info
        display: flex
        flex-direction: column

    &__title-wrapper
        display: flex
        align-items: center
        gap: 20px
        margin-bottom: 20px
        +max-width($tablet)
            gap: 10px
        +max-width($mobail)
            margin-bottom: 12px


    &__icon
        +max-width($tablet)
            max-width: 32px
            max-height: 32px


    &__title
        font: 700 36px/120% $font
        color: #020F25
        +max-width($tablet)
            font: 700 26px/120% $font
        +max-width($mobail)
            font: 700 20px/120% $font

    &__text
        color: $color
        font: 400 24px/150% $font
        margin-bottom: 32px
        margin-left: 72px
        max-width: 560px
        +max-width($tablet)
            font: 400 16px/150% $font
            margin-left: 36px
        +max-width($mobail)
            margin-bottom: 20px
            font: 400 14px/130% $font
            margin-left: 0

    &__link
        display: flex
        justify-content: center
        align-items: center
        border-radius: 27.5px
        width: fit-content
        background-color: $main-color
        border: 1px solid $main-color
        font: 700 16px/24px $font
        letter-spacing: 1px
        color: #fff
        text-transform: uppercase
        margin-left: 72px
        padding: 15px 32px
        transition: color 0.3s ease, background-color 0.3s ease
        +max-width($tablet)
            font: 600 14px/140% $font
            padding: 8px 20px
            margin-left: 36px
            letter-spacing: 0.8px
        +max-width($mobail)
            margin: 0 auto

        &:hover, &:focus, &:active
            background-color: transparent
            color: $main-color
