@import "../config"
@import "/styles/config"

.title
	padding-top: 122px
	margin: 0
	+max-width($tablet)
		padding-top: 60px
	+max-width($mobail)
		padding-top: 12px


	&__top
		font: 80px/132% $font
		font-weight: 700
		font-style: normal
		width: 70%
		+max-width($mobail)
			width: 100%
			font: 30px/125% $font
			font-weight: 700

	&__break
		+max-width($tablet)
			display: block

	&__violet
		border-bottom: 7px solid $btn-link
		width: fit-content
		margin: 0
		display: inline-block
		line-height: 85%
		+max-width($tablet)
			border-bottom: 5px solid $btn-link
		+max-width($mobail)
			border-bottom: 3px solid $btn-link

	&__bot
		color: $color
		font-style: normal
		font: 300 32px/150% $font
		max-width: 70%
		margin-top: 20px
		+max-width($mobail)
			font: 400 18px/132% $font
			margin-top: 20px

.btn
	margin: 0 auto 0 0

	&_wrapper
		margin: 1.25rem 0 0 0
