@import "../config"
@import "/styles/config"


.teamContainer
    display: flex
    flex-direction: column
    gap: 16px

    &__title
        font: 700 48px/120% $font
        +max-width($lg-tablet)
            font: 700 40px/48px $font
        +max-width($mobail)
            font: 700 24px/140% $font

    &__list
        width: 100%
        display: grid
        grid-template-columns: repeat(4, 1fr)
        gap: 24px 40px
        +max-width($desktop)
            grid-template-columns: repeat(4, 1fr)
        +max-width($tablet)
            grid-template-columns: repeat(2, 1fr)
        +max-width($mobail)
            gap: 8px
            grid-template-columns: repeat(1, 1fr)


    &__subtitle
        font: 22px/125% $font
        color: $color
        margin: 12px 0 0 0
        +max-width($tablet)
            font: 18px/125% $font
        +max-width($mobail)
            font: 14px/150% $font
