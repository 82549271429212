@import "../config"
@import "/styles/config"

.developmentList
    padding-bottom: 120px
    margin: 0
    +max-width($tablet)
        padding-bottom: 80px
    +max-width($mobail)
        padding-bottom: 40px


    &__wrapper
        display: flex
        flex-wrap: wrap
        gap: 40px
        transition: all 0.3s ease
        +max-width($tablet)
            flex-direction: column
        +max-width($mobail)
            gap: 12px
