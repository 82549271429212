@import "../config"
@import "/styles/config"


.teamItem
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    min-height: 72px
    padding: 12px 0 12px 28px
    +max-width($mobail)
        padding: 6px 0 6px 16px

    &:before
        position: absolute
        content: ""
        display: block
        left: 0
        top: 0
        height: 100%
        width: 8px
        background-color: #e9f1f6


    &__title
        font: 600 20px/140% $font
        color: $color
        +max-width($sm-mobail)
            font: 600 16px/150% $font

        &--purple
            margin-top: 10px
            color: $main-color
            font-size: 18px


    &__subtitle
        font: 14px/143% $font
        color: $grey
        +max-width($sm-mobail)
            font: 12px/150% $font

