@import "../config"
@import '/styles/config'

$duration: 80s

.runline_title
  font: 700 4rem/120% $font
  width: 75%
  margin: 7.5rem 0 1.825rem

  +max-width($tablet)
    font: 700 2.8rem/125% $font
    width: 100%

  +max-width($mobail)
    font: 700 1.75rem/125% $font
    width: 100%


.runline_wrap
  display: flex
  gap: 3.75rem
  overflow: hidden
  position: relative
  height: 19rem
  margin-bottom: 3.75rem

  +max-width($tablet)
    height: 7.875rem

  & > div
    display: flex
    gap: 3.75rem
    transform: translate(0, 0)
    animation: ticker $duration infinite linear forwards

.runline_wrap_second
  animation: $duration ticker $duration/2 infinite linear forwards


@keyframes ticker
  0%
    transform: translate(0%, 0)

  50%
    transform: translate(-75%, 0)

  100%
    transform: translate(-150%, 0)
