@import "../config"
@import "/styles/config"


.ciphers-block
    background-color: $lightbg
    position: relative
    z-index: 1


    &__title
        margin: 0 0 60px 0
        +max-width($mobail)
            margin: 0 0 46px 0

    &__wrapper
        position: relative
        margin: 0 -16px


    &__grid
        position: relative
        display: flex
        flex-wrap: wrap
        +max-width($xl-tablet)
            flex-direction: column


    &__item
        width: (100%/12)*4
        padding: 0 16px
        position: relative
        +max-width($xl-tablet)
            width: 100%

    &__item + &__item
        +max-width($tablet)
            margin: 60px 0 0 0


    &__top
        position: relative
        display: block

        &:before
            content: ''
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            background-image: url('https://ba5f0191-bf14-4ecb-8111-dfb7ed9d1e60.selstorage.ru/images/svg/rec/rec-ciphers__data-img1.svg')
            background-position: 0 50%
            background-repeat: no-repeat
            background-size: contain


    &__top-content
        position: relative
        display: flex
        width: 100%
        align-items: center
        height: 100%
        min-height: 191px
        z-index: 1
        padding: 0 0 0 40px
        +max-width($mobail)
            min-height: 120px
            padding: 0 0 0 20px


    &__name
        position: relative
        display: block
        color: $link
        font: bold 4.5vw/100% $font
        +min-width(1920px)
            font: bold 80px/100% $font
        +max-width($xl-tablet)
            font: bold 60px/100% $font
        +max-width($mobail)
            font: bold 40px/100% $font


    &__desc
        position: relative
        display: block
        padding: 0 0 0 10px
        font: 600 1.6vw/130% $font
        color: $color
        +min-width(1920px)
            font: 600 30px/130% $font
        +max-width($xl-tablet)
            font: 600 24px/130% $font
        +max-width($mobail)
            font: 600 20px/130% $font


    &__top + &__bottom
        margin: 80px 0 0 0
        +max-width($xl-tablet)
            margin: 15px 0 0 0


    &__bottom
        position: relative
        display: block
        background: $white
        min-width: 192px
        padding: 20px



    &__bottom-title
        position: relative
        display: block
        font: 600 1.6vw/130% $font
        color: $color
        margin: 0 0 12px 0
        +min-width(1920px)
            font: 600 30px/130% $font
        +max-width($xl-tablet)
            font: 600 24px/130% $font
        +max-width($mobail)
            font: 600 20px/130% $font

    &__bottom-row + &__bottom-row
        margin: 10px 0 0 0


    &__bottom-row
        position: relative
        display: flex
        width: 100%


    &__color-text,
    &__text
        position: relative
        display: block
        font: 1.25vw/130% $font
        color: $color
        +min-width(1920px)
            font: 22px/130% $font
        +max-width($xl-tablet)
            font: 18px/130% $font
        +max-width($mobail)
            font: 14px/130% $font


    &__color-text
        color: $link
        font-weight: bold
        padding: 0 5px 0 0






















