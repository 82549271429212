@import "../config"
@import "/styles/config"


.company-title
    position: relative
    display: block
    padding: 120px 0 140px
    +max-width($lg-tablet)
        padding: 100px 0 120px
    +max-width($tablet)
        padding: 100px 0 100px
    +max-width($mobail)
        padding: 80px 0 100px
    +max-width($sm-mobail)
        padding: 60px 0 80px


    &__bg
        position: absolute
        background-position: 100% 50%
        background-repeat: no-repeat
        background-size: contain
        width: 1103px
        height: 780px
        top: 50%
        right: -5%
        transform: translateY(-50%)
        +min-width(1920px)
            right: 0
            width: 1103px
        +max-width($max-width-nopadding)
            width: 900px
            right: -5%
        +max-width($tablet)
            width: 900px
            left: 20%
        +max-width($mobail)
            width: 900px
            left: 0
        +max-width($sm-mobail)
            width: 500px
            left: 10%



    &__wrapper
        display: flex
        justify-content: space-between
        align-items: flex-start
        flex-direction: column
        +max-width($tablet)
            flex-direction: column
            align-items: flex-start


    &__info
        padding: 0 20px
        width: (100%/12)*9
        flex-shrink: 0
        +max-width($tablet)
            width: (100%/12)*10
        +max-width($sm-mobail)
            width: 100%


    &__title
        margin: 0 0 20px 0


    &__subtitle
        font: 300 40px/150% $font
        +max-width($lg-tablet)
            font: 300 30px/150% $font
        +max-width($tablet)
            font: 300 25px/150% $font
        +max-width($mobail)
            font: 300 20px/150% $font


    &__desc
        position: relative
        display: block
        margin: 20px 0 0 0
        font: 22px/160% $font
        +max-width($lg-tablet)
            font: 18px/160% $font
        +max-width($tablet)
            font: 16px/160% $font
        +max-width($mobail)
            font: 14px/160% $font



    &__btn
        border: 1px solid $btn-link
        color: $btn-link
        padding: 0 30px
        margin: 20px 0 0 0
        text-transform: uppercase
        letter-spacing: 1px
        font: 600 13px/46px $font
        border-radius: 27.5px
        transition: all 0.3s ease
        +max-width($xl-desktop)
            font: 600 14px/40px $font
        +max-width($mobail)
            padding: 8px 20px
            letter-spacing: 0.8px
            font: 600 14px/171% $font
            border-radius: 20px

        &:hover
            color: #FFFFFF
            background: $btn-link


.default-title
    margin: 0