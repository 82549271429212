@import "../config"
@import "/styles/config"

.targetCase
    background: $lightbg
    margin: 0 0 120px 0
    +max-width($tablet)
        margin: 0 0 80px 0
    +max-width($mobail)
        margin: 0 0 60px 0


    &__wrapper
        display: flex
        gap: 40px
        padding: 80px 0
        +max-width($tablet)
            flex-direction: column
            gap: 16px
            padding: 40px 0
        +max-width($mobail)
            gap: 4px
            padding: 24px 0


    &__left
        font: 700 48px/120% $font
        min-width: 200px
        +max-width($lg-tablet)
            font: 700 36px/48px $font
        +max-width($mobail)
            font: 700 24px/140% $font


    &__right
        font: 400 30px/140% $font
        max-width: 1040px
        +max-width($lg-tablet)
            font: 400 28px/140% $font
        +max-width($mobail)
            font: 400 18px/140% $font